<template>
    <div class="cashierTrade" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent align="left" :inline="true" v-model="form" size="small">
                <el-row align="left">
                    <el-col :span="5">
                        <el-form-item label="机构组">
                            <el-select
                                v-model="form.deptGroupCode"
                                filterable
                                :loading="loadingDeptGroupFlag"
                                style="width: 180px"
                                @change="changeGroup"
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="group in meta.deptGroups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="机构名称">
                            <el-select placeholder="请选择" v-model="form.deptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptCodes"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="推荐人：" prop="developMember">
                            <el-select v-model="form.developCode" ref="selectStaff" filterable clearable>
                                <el-option v-if="meta.staffs.length" value="" key="" label="全部"></el-option>
                                <el-option
                                    v-for="staff in meta.staffs"
                                    :value="staff.code"
                                    :key="staff.code"
                                    :label="staff.realName"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="会员姓名：" prop="member">
                            <el-input v-model="form.keyword" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="交易日期">
                            <el-date-picker
                                v-model="form.dates"
                                type="daterange"
                                :unlink-panels="true"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerOptions"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                size="small"
                type="primary"
                @click="handleQuery"
                v-if="hasPrivilege('menu.report.memberSummary.open')"
                >查询
            </el-button>
            <el-button
                size="small"
                type="primary"
                @click="handleExport"
                v-if="hasPrivilege('menu.report.memberSummary.export')"
                >导出</el-button
            >
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="420"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="交易机构" width="200" prop="deptName"></el-table-column>

                <el-table-column label="会员姓名" width="300" prop="name" />
                <el-table-column label="收银总额" width="160" prop="paid">
                    <template slot-scope="scope">
                        <span>{{ scope.row.paid | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="收银退款总额" width="160" prop="refund">
                    <template slot-scope="scope">
                        <span>{{ scope.row.refund | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="推荐人" width="160" prop="developerName"></el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import qs from 'qs';
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'MemberConsumeSummary',
    data() {
        return {
            value1: '',
            form: {
                deptGroupCode: '',
                deptCode: '',
                keyword: '',
                developCode: '',
                dates: [this.defaultStartTime(), this.defaultEndTime()],
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            meta: {
                staffs: [],
                deptCodes: [],
                deptGroups: [],
                developers: [],
            },
            loading: false,
            tableData: [],
            url: {
                query: '/report/memberSummary/list',
                groupPeople: '/system/staff/queryExtend',
                queryDeptByGroup: '/system/dept/deptTree',
            },
            loadingDeptGroupFlag: true,
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ],
            },
        };
    },
    mounted() {
        const _this = this;
        //1 查询机构组,机构 -> 机构列表
        UrlUtils.DeptGroup(this, (rst) => {
            _this.meta.deptGroups = rst || [];
            _this.form.deptGroupCode = _this.meta.deptGroups[0].code;
            _this.loadingDeptGroupFlag = false;
            _this.changeGroup();
        });
        //2 查询机构组内所有员工 -> 发展人
    },
    methods: {
        handleQuery() {
            const _this = this;
            if (!this.form['deptGroupCode']) {
                this.$message.error('必须选择机构组');
                return;
            }
            if (!this.form['deptCode']) {
                this.$message.error('必须选择机构');
                return;
            }
            if (!this.form.dates || !this.form['dates'][0]) {
                this.$message.error('必须选择开始日期');
                return;
            }
            if (!this.form.dates || !this.form['dates'][1]) {
                this.$message.error('必须选择结束日期');
                return;
            }
            this.form.page = this.page.page;
            this.form.size = this.page.size;
            this.form.startDate = this.form.dates ? this.form.dates[0] : '';
            this.form.endDate = this.form.dates ? this.form.dates[1] : '';
            Util.queryTable(_this, _this.url.query + '?' + qs.stringify(this.form), null, (data) => {
                _this.tableData = _this.fillData(data.data);
                _this.page.total = data.count;
            });
        },
        handleExport() {
            const _this = this;
            if (!_this.form['deptGroupCode']) {
                _this.$message.error('必须选择机构组');
                return;
            }
            if (!_this.form['deptCode']) {
                _this.$message.error('必须选择机构');
                return;
            }
            if (!_this.form.dates || !_this.form['dates'][0]) {
                _this.$message.error('必须选择开始日期');
                return;
            }
            if (!_this.form.dates || !_this.form['dates'][1]) {
                _this.$message.error('必须选择结束日期');
                return;
            }
            _this.form.startDate = _this.form.dates ? _this.form.dates[0] : '';
            _this.form.endDate = _this.form.dates ? _this.form.dates[1] : '';
            UrlUtils.Export(_this, '会员交易汇总', '/report/memberSummary/excel', _this.form);
        },
        changeGroup() {
            const _this = this;
            _this.form.regDept = '';
            _this.form.keyword = '';
            _this.form.developCode = '';
            _this.form.deptCode = '';
            UrlUtils.QueryRemote(
                this,
                _this.url.queryDeptByGroup + '?deptGroupCode=' + _this.form.deptGroupCode,
                (rst) => {
                    _this.meta.deptCodes = rst;
                    if (_this.meta.deptCodes.length >= 1) {
                        _this.form.deptCode = _this.meta.deptCodes[0].code;
                    }
                }
            );
            if (_this.$store.state.session.staffType) {
                _this.meta.staffs = [
                    { code: _this.$store.state.session.code, realName: _this.$store.state.session.name },
                ];
            } else {
                UrlUtils.QueryRemote(
                    this,
                    _this.url.groupPeople + '?deptGroupCode=' + _this.form.deptGroupCode,
                    (rst) => {
                        _this.meta.staffs = rst;
                    }
                );
            }
        },
        fillData(array) {
            const _this = this;
            const _deptName = _this.meta.deptCodes.find((a) => a.code == _this.form.deptCode).name;
            return (array || []).map((value) => {
                value.deptName = _deptName;
                if (value['developCode']) {
                    const stf = _this.meta.staffs.find((a) => a.code == value['developCode']);
                    if (stf) {
                        value.developerName = stf['realName'];
                    } else {
                        //如果员工的推荐人是其他机构组,不显示
                        value.name = value.name + '(组外会员)';
                        value.developerName = '';
                    }
                } else {
                    value.developerName = '';
                }

                return value;
            });
        },
    },
};
</script>
